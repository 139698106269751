import { createContext } from 'react';

import { Variant } from '@templates/Alert';
import Message from 'types/Message';

export type SnackbarContextType = {
  showMessage: (type: Variant, message: Message, timeout?: number) => string;
  closeMessage: (id: string) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  showMessage: () => '',
  closeMessage: () => {},
});
