import { useEffect, useReducer } from 'react';

import fetchReducer, { initialState, startFetching, loadData, handleError, State } from 'reducers/fetchReducer';
import ApiRoute, { GetResponse } from 'utils/apiRoute';
import { clientApiFetch } from 'utils/fetch';
import { ErrorResponse } from 'utils/apiError';
import useAuth from 'hooks/useAuth';
import useToken from 'hooks/useToken';

type Props<P extends Record<string, unknown>> = {
  payload?: P;
  options?: RequestInit;
  manual?: boolean;
  withoutTokenRefresh?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetchResult<C extends ApiRoute<any, any | never>> = Pick<
  State<GetResponse<C>>,
  'data' | 'error' | 'headers'
>;

type UseFetchReturnType<R extends Record<string, unknown>, P extends Record<string, unknown>> = [
  (payload?: P, options?: RequestInit) => Promise<Pick<State<R>, 'data' | 'error' | 'headers'>>,
  State<R>,
];

const useFetch = <R extends Record<string, unknown>, P extends Record<string, unknown>>(
  route: ApiRoute<R, P>,
  { payload, options = {}, manual = true, withoutTokenRefresh = false }: Props<P> = {}
): UseFetchReturnType<R, P> => {
  const auth = useAuth();
  const { refreshInvalidToken, receiveToken } = useToken();
  const [state, dispatch] = useReducer(fetchReducer<R>, { ...initialState, loading: !manual });

  const refetch: UseFetchReturnType<R, P>[0] = async (refetchPayload, refetchOptions) => {
    try {
      dispatch(startFetching());
      if (!withoutTokenRefresh) {
        await refreshInvalidToken();
      }
      const finalPayload = refetchPayload || payload || <P>{};

      const { data, headers, error } = await clientApiFetch(route, finalPayload, {
        ...options,
        ...refetchOptions,
        headers: {
          ...(options?.headers || refetchOptions?.headers || {}),
          ...(auth.jwt ? { 'x-jwt': auth.jwt } : {}),
        },
      });

      if (data) {
        dispatch(loadData(data, headers));
      } else if (error?.code === 'invalid-jwt') {
        auth.clear();
        await receiveToken();
        return refetch(refetchPayload);
      } else if (error) {
        dispatch(handleError(error));
      }

      return { data, headers, error };
    } catch (e) {
      const internalError: ErrorResponse = { statusCode: 500, code: 'INTERNAL', message: e.message };
      dispatch(handleError(internalError));
      return { data: undefined, headers: undefined, error: internalError };
    }
  };

  useEffect(() => {
    if (!manual) {
      refetch();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return [refetch, state];
};

export default useFetch;
