import jwtDecode from 'jwt-decode';

import { DecodedToken } from 'types/DecodedToken';

export class Auth {
  jwt?: string;

  private observers: ((auth: this) => void)[] = [];

  get token(): string | undefined {
    return this.jwt?.includes('.') ? this.jwt.replace('Bearer ', '') : undefined;
  }

  set token(token: string | undefined) {
    this.jwt = token ? `Bearer ${token}` : undefined;
    this.observers.forEach((callback) => callback(this));
  }

  clear() {
    this.jwt = undefined;
  }

  get decodedToken(): DecodedToken | Record<string, never> {
    let decodedToken;

    try {
      decodedToken = this.token ? jwtDecode<DecodedToken>(this.token) : {};
    } catch {
      decodedToken = {};
    }

    return decodedToken;
  }

  get claims() {
    return this.decodedToken['https://hasura.io/jwt/claims'];
  }

  get userId() {
    return this.claims?.['x-hasura-user-id'];
  }

  get shopId() {
    return this.claims?.['x-hasura-shop-id'];
  }

  subscribe(callback: (auth: this) => void) {
    this.observers.push(callback);
  }
}

export default new Auth();
