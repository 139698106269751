export const PHONE_NUMBER_FORMAT = /^(\+48\s?)?(\d{9,15})$/;
export const POST_CODE_FORMAT = /^\d{2}-\d{3}$/;
export const NIP_FORMAT = /^\d{10}$/;
export const BLIK_FORMAT = /^\d{3} \d{3}$/;
export const CREDIT_CARD_FORMAT = /^\d{4} \d{4} \d{4} \d{4}$/;
export const EMAIL_FORMAT = /([a-zA-Z0-9]+[\w\d+.-]*@[a-zA-Z0-9]+[\w\d+.-]{1,63}\.[a-zA-Z]{2,})/;
export const PASSWORD_POLICY = /(?=.*\d)(?=.*[A-Z])/;
export const NO_WHITESPACE = /^[^\s]*$/;
export const LINK_MARKUP_FORMAT = /\[([^\]]+)\]\(([^)]+)\)/;

export const constraints = {
  nameLength: {
    min: 2,
  },
  inputLength: {
    max: 255,
  },
  passwordLength: {
    min: 8,
  },
  textLength: {
    max: 65535,
  },
  mailLength: {
    max: 196605,
  },
};
