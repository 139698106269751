// eslint-disable-next-line no-use-before-define
import React, { useContext, useEffect, useState } from 'react';
import { NextComponentType, NextPageContext } from 'next';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
// import { NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';

import withProviders from 'core/hocs/withProviders';
import { noscriptCss } from 'core/noscript.styles';
import { fontsCss } from 'core/fonts.styles';
import 'styles/global.css';

import config from 'config';
import { appVersion } from 'consts/app';
import { ConfigContext } from '@providers/ConfigProvider';
import ProgressBar from '@components/atoms/ProgressBar';
import ErrorPage from '@templates/ErrorPage';
import CompareFab from '@components/CompareFab';
import AnalyticsKeyPress from '@components/keypress/AnalyticsKeyPress';
// import { trackEvent } from 'utils/analytics';
import isBrowser from 'utils/isBrowser';
import useTrack from 'hooks/useTrack';
import useToken from 'hooks/useToken';

if (isBrowser && !('IntersectionObserver' in window)) {
  import('intersection-observer');
}

const { routes } = config;

// export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric): void {
//   // @ts-expect-error what with uaTrackingId??
//   trackEvent('web-vitals', name, {
//     event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
//     value: Math.round(name === 'CLS' ? value * 1000 : value),
//     event_label: id,
//     non_interaction: true,
//   });
// }

const globalLoaderExcludedPaths = [routes.search.href];

type ExtendedNextComponentType = NextComponentType<NextPageContext> & { withCompareFab?: boolean };

const MyApp = ({
  Component,
  pageProps, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AppProps<Record<string, any>> & { Component: ExtendedNextComponentType }): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const { refreshInvalidToken } = useToken();
  const { contact } = useContext(ConfigContext);
  const { trackPageView } = useTrack();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = async (url: string) => {
      if (
        router.asPath !== url &&
        [router.asPath, url].some((pathToCheck) =>
          globalLoaderExcludedPaths.some((path) => !pathToCheck.includes(path))
        )
      ) {
        setIsLoading(true);
      }
      if (router.asPath !== url) {
        await refreshInvalidToken(
          url.replace(/(\/checkout\/\w*\/)([\w-]+)/, '$1[hash]').replace(/(\/quotation\/\w*\/)(\d+)/, '$1[id]')
        );
      }
    };
    const handleRouteChange = (url: string) => {
      setIsLoading(false);
      trackPageView(url, router.asPath);
    };
    const handleRouteChangeError = () => {
      setIsLoading(false);
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeError', handleRouteChangeError);
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeError', handleRouteChangeError);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, router.events]);

  useEffect(() => {
    if (pageProps.appVersion && pageProps.appVersion !== appVersion) {
      window.location.reload();
    }
  }, [pageProps.appVersion]);

  return (
    <>
      <Head>
        <title>{`${contact.appName}`}</title>
        <link rel="preload" href="/fonts/lato-reg.woff2" as="font" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/lato-bold.woff2" as="font" crossOrigin="anonymous" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* eslint-disable-next-line react/no-danger */}
        <style dangerouslySetInnerHTML={{ __html: fontsCss }} />
        <noscript>
          {/* eslint-disable-next-line react/no-danger */}
          <style dangerouslySetInnerHTML={{ __html: noscriptCss }} />
        </noscript>
      </Head>
      <ProgressBar visible={isLoading} fixed />
      <AnalyticsKeyPress />
      {pageProps.error ? (
        <ErrorPage errorCode={pageProps.error} />
      ) : (
        <>
          {Component.withCompareFab && <CompareFab bottomOffset={pageProps.compareFabBottomOffset} />}
          <Component {...pageProps} />
        </>
      )}
    </>
  );
};

export default withProviders(MyApp);
